import { Option, StepperConfig } from "src/types/common";
import { path } from "./path";

export const indexing = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
];

export const OtpType = {
  EMAIL: "EMAIL",
  AADHAAR: "AADHAAR",
  MOBILE: "MOBILE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

export const USER_TYPE2 = {
  INDIVIDUAL: "INDIVIDUAL",
  ENTITY: "ORGANIZATION",
  IMPLEMENTING_OFFICER: null,
};

export const devTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  ORGANIZATION: "ORGANIZATION",
  OTHER: "OTHER",
};

export const ownerShipRecordtype = {
  "03790557-5c1f-4441-8bda-9304374865c0": "NOC",
  "fb2c6d5d-3265-4539-a180-bfde07748eed": "Lease",
};

export const SidebarMenu = [
  {
    icon: "/assets/svg/marketIcon.svg",
    iconSelected: "/assets/svg/marketIcon.svg",
    text: "DashBoard",
    path: `/market-watch`,
    id: "Dashboard",
    type: [
      USER_TYPE2.INDIVIDUAL,
      USER_TYPE2.ENTITY,
      USER_TYPE2.IMPLEMENTING_OFFICER,
    ],
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Eco-Restoration Blocks",
    path: "/",
    id: "my-projects",
    type: [
      USER_TYPE2.INDIVIDUAL,
      USER_TYPE2.ENTITY,
      USER_TYPE2.IMPLEMENTING_OFFICER,
    ],
  },
  {
    icon: "/assets/svg/bookIcon.svg",
    iconSelected: "/assets/svg/bookIcon.svg",
    text: "Cost Estimates",
    path: `/order-book`,
    id: "order-book",
    type: [USER_TYPE2.INDIVIDUAL, USER_TYPE2.IMPLEMENTING_OFFICER],
  },
  {
    icon: "/assets/svg/bookIcon.svg",
    iconSelected: "/assets/svg/bookIcon.svg",
    text: "Fund Application",
    path: `/fund-application`,
    id: "raise-fund",
    type: [USER_TYPE2.INDIVIDUAL, USER_TYPE2.IMPLEMENTING_OFFICER],
  },
  {
    icon: "/assets/svg/folderIcon.svg",
    iconSelected: "/assets/svg/selectedFolderIcon.svg",
    text: "Progress Details",
    path: "/Demo",
    options: [
      {
        icon: "/assets/svg/bookIcon.svg",
        iconSelected: "/assets/svg/bookIcon.svg",
        text: "Digital Cost Estimate",
        path: `/cost-estimate-digital`,
        id: "cost-estimate-details",
        type: [USER_TYPE2.IMPLEMENTING_OFFICER],
      },
      {
        icon: "/assets/svg/bookIcon.svg",
        iconSelected: "/assets/svg/bookIcon.svg",
        text: "Update Progress",
        path: `/update-progress-details`,
        id: "update-progress-details",
        type: [USER_TYPE2.INDIVIDUAL, USER_TYPE2.IMPLEMENTING_OFFICER],
      },
    ],
    id: "progress-details",
    type: [USER_TYPE2.INDIVIDUAL, USER_TYPE2.IMPLEMENTING_OFFICER],
  },
  {
    icon: "/assets/svg/bookIcon.svg",
    iconSelected: "/assets/svg/bookIcon.svg",
    text: "Progress Details",
    path: `/update-progress-details`,
    id: "update-progress-details",
    type: [USER_TYPE2.ENTITY],
  },
  {
    icon: "/assets/svg/portfolioIcon.svg",
    iconSelected: "/assets/svg/portfolioIcon.svg",
    text: "Implementing Officer",
    path: `/add-implementing-officer`,
    id: "implementing-officer",
    type: [USER_TYPE2.INDIVIDUAL],
  },
];

export const helper = [
  {
    label: "Green Credit Project Developer",
    value: "greenCreditProjectDeveloper",
  },
];

export const userTypeOptions = [
  {
    label: "Green Credit Project Developer",
    value: "greenCreditProjectDeveloper",
  },
  { label: "Certifiers", value: "certifier" },
  { label: "Verifiers", value: "verifier" },
  { label: "Buyers", value: "buyers" },
];

export const DOCUMENT_ENUM = {
  CIN: "CIN",
  AADHAAR: "AADHAAR",
  GST: "GST",
  PAN: "PAN",
};

export enum PROJECT_TYPE {
  LAND_LEASED = "LAND_LEASED",
  TREE_PLANTATION = "TREE_PLANTATION",
}
export enum PROJECT_TYPE_STRING {
  LAND_LEASED = "Land Leased",
  TREE_PLANTATION = "Tree Plantation",
}

export enum PROJECT_STATUS {
  DRAFT = "In Review",
  IMAGE_PENDING = "Image Pending",
  DECLARATION_PENDING = "Declaration Pending",
  VERIFICATION_PENDING = "Verification Pending",
  REVIEWED = "Reviewed",
  REVIEW_FAILED = "Review Failed",
  COMPLETED = "Completed",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  FLAGGED = "Flagged",
}

export enum CHIP_STATUS {
  DRAFT = "draft",
  IMAGE_PENDING = "inactive",
  DECLARATION_PENDING = "draft",
  VERIFICATION_PENDING = "draft",
  REVIEWED = "active",
  REVIEW_FAILED = "error",
  COMPLETED = "active",
  APPROVED = "active",
  REJECTED = "error",
  FLAGGED = "inactive",
}

export const reason = {
  REJECTED: {
    heading: "Reason For Rejection",
    text: "Text for reason",
  },
};

export enum DECLERATION_TYPE {
  COMPLIANCE = "COMPLIANCE",
  VOLUNTARY = "VOLUNTARY",
  ACA = "ACA",
}
export enum SPECIES_TYPE {
  FORESTRY_SPECIES = "FORESTRY_SPECIES ",
  HORTICULTURE_SPECIES = "HORTICULTURE_SPECIES",
}

export const declerationTypeOptions = [
  {
    label: "Voluntary",
    value: DECLERATION_TYPE.VOLUNTARY,
  },
  {
    label: "Compliance",
    value: DECLERATION_TYPE.COMPLIANCE,
  },
  {
    label: "ACA",
    value: DECLERATION_TYPE.ACA,
  },
];
export const speciesTypeOptions = [
  {
    label: "FORESTRY SPECIES",
    value: SPECIES_TYPE.FORESTRY_SPECIES,
  },
  {
    label: "HORTICULTURE SPECIES",
    value: SPECIES_TYPE.HORTICULTURE_SPECIES,
  },
];

export const USER_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  ENTITY: "ORGANIZATION",
};

export const documentUpload = [
  {
    label: "CIN/GSTIN UIN",
    value: DOCUMENT_ENUM.CIN,
  },
  {
    label: "PAN",
    value: DOCUMENT_ENUM.PAN,
  },
];

export const devTypeOptions = [
  {
    label: "Forest Department",
    value: USER_TYPE.INDIVIDUAL,
  },
  { label: "Entity/Private Sector/Philanthropy", value: USER_TYPE.ENTITY },
];

export const countryCode = [
  {
    label: "+91",
    value: "+91",
  },
];

export const rainFallZoneOption = [
  {
    label: "500-1000mm",
    value: "500-1000mm",
  },
  {
    label: "200-600mm",
    value: "200-600mm",
  },
  {
    label: "300-700mm",
    value: "300-700mm",
  },
];

export const industryType = [
  {
    label: "Utility",
    value: "Utility",
  },
];

export const projectDuration = [
  {
    label: "10 Year",
    value: "10 Year",
  },
  {
    label: "20 Year",
    value: "20 Year",
  },
  {
    label: "30 Year",
    value: "30 Year",
  },
];

export const userType = {
  // not using currently
  GREEN_CREDIT_DEVELOPER: "Green Credit Project Developer",
  CERTIFIERS: "certifier",
  VERIFIERS: "verifier",
  BUYERS: "buyers",
};

export const stepperConfig: StepperConfig[] = [
  {
    userType: "Implementing Agency",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Type Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Agency Details",
        isActive: true,
        route: path.agencyDetails,
      },
      {
        id: 3,
        isCompleted: false,
        name: "Bank Details",
        isActive: false,
        route: path.bankDetails,
      },
      {
        id: 4,
        isCompleted: false,
        name: "Nodal Officer",
        isActive: false,
        route: path.nodalOfficerDetails,
      },
      {
        id: 5,
        isCompleted: false,
        name: "Implementing Officer",
        isActive: false,
        route: path.implementingOfficerDetails,
      },
    ],
  },
  // {
  //   userType: "Registration of Forest Department",
  //   devType: "ORGANIZATION",
  //   steps: 4,
  //   stepperData: [
  //     {
  //       id: 1,
  //       isCompleted: true,
  //       name: "User Details",
  //       isActive: false,
  //       route: path.onboarding,
  //     },
  //     {
  //       id: 2,
  //       isCompleted: false,
  //       name: "Entity Details", // previously It was organisation
  //       isActive: true,
  //       route: path.organizationDetails,
  //     },
  //     {
  //       id: 3,
  //       isCompleted: false,
  //       name: "Document Upload",
  //       isActive: false,
  //       route: path.documentUpload,
  //     },
  //     {
  //       id: 4,
  //       isCompleted: false,
  //       name: "Address Details",
  //       isActive: false,
  //       route: path.addressDetails,
  //     },
  //   ],
  // },
  {
    userType: "Registration of land parcel by Forest Department",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Type Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: path.personalDetails,
      },
      {
        id: 3,
        isCompleted: false,
        name: "Aadhaar Verification",
        isActive: false,
        route: path.aadhaarVerification,
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: path.addressDetails,
      },
    ],
  },
  {
    userType: "Registration of land parcel by Forest Department",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Entity Details", // previously It was organisation
        isActive: true,
        route: path.organizationDetails,
      },
      {
        id: 3,
        isCompleted: false,
        name: "Document Upload",
        isActive: false,
        route: path.documentUpload,
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: path.addressDetails,
      },
    ],
  },
  // {
  //   userType: "Registration of Entity/Private Sector/Philanthropy",
  //   devType: "INDIVIDUAL",
  //   steps: 3,
  //   stepperData: [
  //     {
  //       id: 1,
  //       isCompleted: true,
  //       name: "User Type Details",
  //       isActive: false,
  //       route: path.onboarding,
  //     },
  //     {
  //       id: 2,
  //       isCompleted: false,
  //       name: "Personal Details",
  //       isActive: true,
  //       route: path.personalDetails,
  //     },
  //     {
  //       id: 3,
  //       isCompleted: false,
  //       name: "Aadhaar Verification",
  //       isActive: false,
  //       route: path.aadhaarVerification,
  //     },
  //     {
  //       id: 4,
  //       isCompleted: false,
  //       name: "Address Details",
  //       isActive: false,
  //       route: path.addressDetails,
  //     },
  //   ],
  // },
  {
    userType: "Entity/ Green Credit Applicant",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Entity Details", // previously It was organisation
        isActive: true,
        route: path.entityDetails,
      },
      {
        id: 3,
        isCompleted: false,
        name: "ID and Documents Upload",
        isActive: false,
        route: path.organizationDetails,
      },
      {
        id: 4,
        isCompleted: false,
        name: "Contact Details",
        isActive: false,
        route: path.contactDetails,
      },
    ],
  },
  {
    userType: "buyers",
    devType: "INDIVIDUAL",
    steps: 3,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Personal Details",
        isActive: true,
        route: path.onboarding,
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: path.onboarding,
      },
    ],
  },
  {
    userType: "buyers",
    devType: "ORGANIZATION",
    steps: 4,
    stepperData: [
      {
        id: 1,
        isCompleted: true,
        name: "User Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 2,
        isCompleted: false,
        name: "Organization Details",
        isActive: true,
        route: path.onboarding,
      },
      {
        id: 3,
        isCompleted: false,
        name: "Additional Details",
        isActive: false,
        route: path.onboarding,
      },
      {
        id: 4,
        isCompleted: false,
        name: "Address Details",
        isActive: false,
        route: path.onboarding,
      },
    ],
  },
];

export enum ProjectType {
  "WATER_CONSERVATION" = "WATER_CONSERVATION",
  "TREE_PLANTATION" = "TREE_PLANTATION",
  "LAND_LEASED" = "LAND_LEASED",
}

export const ROLES = {
  REVIEWER: "Reviewer",
  APPROVER: "Approver",
  INDIVIDUAL: "Individual",
  ENTITY: "Entity",
  ORGANIZATION: "Entity",
};

export enum PROJECT_DOCUMENT_TYPE_MAP {
  SELF_DECLARATION_FORM = "Self declaration form",
  LAND_OWNERSHIP = "Land Ownership",
  PLOT_LAYOUT = "Plot layout",
  AREA_SHAPE = "Area shape",
  PROJECT_LAYOUT = "Project layout",
  BANK_PASSBOOK = "Bank passbook",
  BANK_CHEQUE = "bank cheque",
  BANK_ACCOUNT_RECORD = "Bank account record",
  SAPLING_RECEIPT = "Sapling receipt",
  PAYMENT_RECEIPT = "Payment receipt",
  WORKING_PERMIT = "Working permit",
}

// export enum PROJECT_DOCUMENT_TYPE {
//   SELF_DECLARATION_FORM = "SELF_DECLARATION_FORM",
//   LAND_OWNERSHIP = "LAND_OWNERSHIP",
//   PLOT_LAYOUT = "PLOT_LAYOUT",
//   AREA_SHAPE = "AREA_SHAPE",
//   PROJECT_LAYOUT = "PROJECT_LAYOUT",
//   BANK_PASSBOOK = "BANK_PASSBOOK",
//   BANK_CHEQUE = "BANK_CHEQUE",
//   BANK_ACCOUNT_RECORD = "BANK_ACCOUNT_RECORD",
//   SAPLING_RECEIPT = "SAPLING_RECEIPT",
//   PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
//   WORKING_PERMIT = "WORKING_PERMIT",
// }

export enum ImageType {
  SELF_DECLARATION_FORM = "SELF_DECLARATION_FORM",
  LAND_OWNERSHIP = "LAND_OWNERSHIP",
  PLOT_LAYOUT = "PLOT_LAYOUT",
  AREA_SHAPE = "AREA_SHAPE",
  PROJECT_LAYOUT = "PROJECT_LAYOUT",
  BANK_ACCOUNT_RECORD = "BANK_ACCOUNT_RECORD",
  SAPLING_RECEIPT = "SAPLING_RECEIPT",
  PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
  WORKING_PERMIT = "WORKING_PERMIT",
}

export const organizationOptions: Option[] = [
  {
    label: "Farmer Producer Organisation(FPO)",
    value: "FPO",
  },
  {
    label: "Forest Management Committee(FMC)",
    value: "FMC",
  },
  {
    label: "Joint Forest Management Committee(JFMC)",
    value: "JFMC",
  },
  {
    label: "Self Help Group(SHG)",
    value: "SHG",
  },
  {
    label: "Eco-Development committee(EDC)",
    value: "EDC",
  },
  {
    label: "Urban Local Bodies(ULB)",
    value: "ULB",
  },
  {
    label: "Non-Government Organisation(NGO)",
    value: "NGO",
  },
  {
    label: "Enterprise",
    value: "enterprise",
  },
  {
    label: "Company",
    value: "company",
  },
  {
    label: "Others",
    value: "others",
  },
];

// export const incomeRageOptions = [
//   { label: "4000-5000 lpa", value: "4-5" },
//   { label: "5000-6000 lpa", value: "5-6" },
//   { label: "6000-7000 lpa", value: "6-7" },
// ];
// export const institutionTypeOptions = [
//   { label: "Cooperative", value: "Cooperative" },
//   { label: "fp", value: "fp" },
//   { label: "FMC", value: "FMC" },
//   { label: "SHGs", value: "SHGs" },
//   { label: "Eco-development committee", value: "Eco-development" },
//   { label: "Urban", value: "Urban" },
//   { label: "rural", value: "rural" },
// ];
// export const stateOptions = [
//   { label: "delhi", value: "delhi" },
//   { label: "haryana", value: "haryana" },
//   { label: "up", value: "up" },
// ];

export const ladUseTypeOption = [
  { label: "agricultural", value: "agricultural" },
  { label: "urban ", value: "urban " },
  { label: "rural", value: "rural" },
];

export const genderTypeOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const activityPurposeOptions = [
  { label: "Compliance", value: DECLERATION_TYPE.COMPLIANCE },
  { label: "Voluntary", value: DECLERATION_TYPE.VOLUNTARY },
  {
    label: "Accredited Compensatory Afforestation (ACA)",
    value: DECLERATION_TYPE.ACA,
  },
];
export const landTypeOptions = [
  { label: "Degraded Forest Land", value: "Degraded Forest Land" },
  { label: "Watershed areas", value: "Watershed areas" },
  { label: "Waste Land", value: "Waste Land" },
  { label: "Community Land", value: "Community Land" },
  {
    label: "Owned by Individuals/Organisations",
    value: "Owned by Individuals/Organisations",
  },
];

export const tooltipContent = {
  reject: {
    title: "Reason For Rejection",
    desc: "Lorem ipsum dolor sit amet consectetur. Adipiscing molestie duis leo tortor tellus.",
  },
};

export const homeTabs = [
  {
    label: "Plantation Block",
    value: ProjectType.TREE_PLANTATION,
    type: "Entity/ Green Credit Applicant",
  },
  {
    label: "Land Parcel",
    value: ProjectType.LAND_LEASED,
    type: "Implementing Agency",
  },
];

export const RoleIds = {
  "Green Credit Project Developer": "b9c4e0f5-c062-4ad5-9cb3-fc3e24e918c1",
  Approver: "36a8cd90-9fa4-4c08-ab8f-f8faf96c64f1",
  Reviewer: "bf03a589-86c0-4f1f-be20-9ebec7c7d800",
};

export const registeredLandTabs = [
  {
    label: "Registered",
    value: undefined,
  },
  {
    label: "SNO Verification",
    value: "NODAL_VERIFICATION_PENDING",
  },
  {
    label: "Admin Verification",
    value: "VERIFICATION_PENDING",
  },
  { label: "SNO Query", value: "NODAL_UNDER_QUERY" },
  { label: "Admin Query", value: "UNDER_QUERY" },
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
];

export const entityTabs = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Demand Note Pending",
    value:
      "REGENERATE_DEMAND_NOTE,GENERATE_DEMAND_NOTE,VERIFY_DEMAND_NOTE,UNDER_QUERY,NODAL_UNDER_QUERY,NODAL_VERIFY_DEMAND_NOTE",
  },
  {
    label: "Payment Pending",
    value: "PAYMENT_PENDING",
  },
  { label: "Payment Under Verfication", value: "VERIFICATION_PENDING" },
  { label: "Payment Approved", value: "APPROVED" },
];

export const registeredEntitiesTabs = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Cost Estimate Pending",
    value: "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
  },
  {
    label: "SNO Verification",
    value: "NODAL_VERIFY_DEMAND_NOTE",
  },
  {
    label: "Admin Verification",
    value: "VERIFY_DEMAND_NOTE",
  },
  { label: "SNO Query", value: "NODAL_UNDER_QUERY" },
  { label: "Admin Query", value: "UNDER_QUERY" },
  { label: "Payment Pending", value: "PAYMENT_PENDING" },
  {
    label: "Payment Received By Admin",
    value: "APPROVED,VERIFICATION_PENDING",
  },
];

export const fundPaymentTabs = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Recived FA From IO",
    value: "CREATED",
  },
  {
    label: "FA Pending With Admin",
    value: "RAISED",
  },
  {
    label: "SNO Query",
    value: "NODAL_UNDER_QUERY",
  },
  {
    label: "Admin Query",
    value: "UNDER_QUERY",
  },
  {
    label: "Payment Released By Admin",
    value: "APPROVED",
  },
  {
    label: "Payment Receipt Uploaded",
    value: "PAYMENT_ACKNOWLEDGED",
  },
];

export const fundPaymentTabsIO = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "FA to SNO",
    value: "CREATED",
  },
  {
    label: "FA Pending With Admin",
    value: "RAISED",
  },
  {
    label: "SNO Query",
    value: "NODAL_UNDER_QUERY",
  },
  {
    label: "Admin Query",
    value: "UNDER_QUERY",
  },
  {
    label: "Payment Released By Admin",
    value: "APPROVED",
  },
];

export const costEstimateDetails = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Save & Draft",
    value: "DRAFT",
  },
  {
    label: "Submitted",
    value: "SUBMIT_TO_SNO,SUBMIT_TO_ADMIN",
  },
];

export const statusMessages = {
  UNDER_QUERY: "Under Query",
  GENERATE_DEMAND_NOTE: "Generate Estimated Amount",
  REGENERATE_DEMAND_NOTE: "Regenerate Estimated Amount",
};

export const statusMessages2 = {
  UNDER_QUERY: "ADMIN QUERY",
  VERIFY_DEMAND_NOTE: "UNDER VERIFICATION",
  GENERATE_DEMAND_NOTE: "COST ESTIMATE PENDING",
  REGENERATE_DEMAND_NOTE: "RESUBMIT COST ESTIMATE",
  NODAL_VERIFY_DEMAND_NOTE: "SNO VERIFICATION PENDING",
  NODAL_UNDER_QUERY: "SNO QUERY",
  PAYMENT_PENDING: "PAYMENT PENDING",
};

export const entityStatusMessages = {
  UNDER_QUERY: "Cost Est. Under Query",
  NODAL_VERIFY_DEMAND_NOTE: "Cost Est. Pending",
  NODAL_UNDER_QUERY: "Cost Est. Pending",
  GENERATE_DEMAND_NOTE: "Cost Est. Pending",
  REGENERATE_DEMAND_NOTE: "Cost Est. Pending",
  VERIFY_DEMAND_NOTE: "Cost Est. Under Verfication",
  APPROVED: "Payment Done",
  // PAYMENT_PENDING: "Payment Pending",
};

export const units = [
  {
    label: "Kg",
    value: "Kg",
  },
  {
    label: "No`s",
    value: "No`s",
  },
  {
    label: "No`s/Ha",
    value: "No`s/Ha",
  },
  {
    label: "m",
    value: "m",
  },
  {
    label: "Mandays",
    value: "Mandays",
  },
  {
    label: "Ha",
    value: "Ha",
  },
  {
    label: "RMTs",
    value: "RMTs",
  },
  {
    label: "Lump Sum(LS)",
    value: "Lump Sum(LS)",
  },
  {
    label: "cm",
    value: "cm",
  },
  {
    label: "Per Plant",
    value: "Per Plant",
  },
  {
    label: "Per Ha",
    value: "Per Ha",
  },
];

export const calenderYear = [
  {
    label: "2024-2025",
    value: "2024-2025",
  },
  {
    label: "2025-2026",
    value: "2025-2026",
  },
  {
    label: "2026-2027",
    value: "2026-2027",
  },
  {
    label: "2027-2028",
    value: "2027-2028",
  },
  {
    label: "2028-2029",
    value: "2028-2029",
  },
  {
    label: "2029-2030",
    value: "2029-2030",
  },
  {
    label: "2030-2031",
    value: "2030-2031",
  },
  {
    label: "2031-2032",
    value: "2031-2032",
  },
  {
    label: "2032-2033",
    value: "2032-2033",
  },
  {
    label: "2033-2034",
    value: "2033-2034",
  },
];

export const year = [
  {
    label: "1st Year",
    value: "1st Year",
  },
  {
    label: "2nd Year",
    value: "2nd Year",
  },
  {
    label: "3rd Year",
    value: "3rd Year",
  },
  {
    label: "4th Year",
    value: "4th Year",
  },
  {
    label: "5th Year",
    value: "5th Year",
  },
  {
    label: "6th Year",
    value: "6th Year",
  },
  {
    label: "7th Year",
    value: "7th Year",
  },
  {
    label: "8th Year",
    value: "8th Year",
  },
  {
    label: "9th Year",
    value: "9th Year",
  },
  {
    label: "10th Year",
    value: "10th Year",
  },
  {
    label: "Other Necessary Provisions",
    value: "Other Necessary Provisions",
  },
];
