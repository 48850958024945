import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useAppSelector } from "src/redux/store";
import PreviewIcon from "@mui/icons-material/Preview";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  status?: string;
};

export function UpdateProgress({
  data,
  topHeader,
  onEdit,
  onDelete,
  status,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  const user = useAppSelector((state) => state.root.user);

  const calculateRowSpan = (data, key) => {
    const rowSpans = {};
    let count = 0;
    let currentKey = null;

    data.forEach((row, index) => {
      if (row[key] !== currentKey) {
        currentKey = row[key];
        count = 1;
        rowSpans[index] = 1;
      } else {
        count += 1;
        rowSpans[index - count + 1] = count;
      }
    });

    return rowSpans;
  };

  // Calculate row spans for `mainComponent` and `year`
  const mainComponentRowSpan = calculateRowSpan(data, "mainComponent");
  const yearRowSpan = calculateRowSpan(data, "year");

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
            {status === "SUBMIT_TO_SNO" &&
              (user?.data?.userType === null ? (
                <TableCell align="center" {...styles("cell")}>
                  Update Progress
                </TableCell>
              ) : (
                <TableCell align="center" {...styles("cell")}>
                  View Detailed Progress
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={index}>
                {yearRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={yearRowSpan[index]}
                    {...styles("cell", { width: "100px" })}
                  >
                    {row.year}
                  </TableCell>
                )}
                {/* Main Component Column with Row Span */}
                {mainComponentRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={mainComponentRowSpan[index]}
                    {...styles("cell", { width: "200px" })}
                  >
                    {row.mainComponent}
                  </TableCell>
                )}

                <TableCell
                  align="center"
                  {...styles("cell", { width: "250px" })}
                >
                  {row?.subComponent}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "250px" })}
                >
                  {row.perticularOfItem}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.unit}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.numberOfUnits}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.costRequired}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.progressInUnits}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.costProgress}
                </TableCell>
                {row.status === "SUBMIT_TO_SNO" &&
                  index !== data.length - 1 && (
                    <TableCell
                      align="center"
                      {...styles("cell", { width: "100px" })}
                    >
                      <>
                        {user?.data?.userType === null ? (
                          <Typography
                            variant="body2"
                            color="primary"
                            onClick={() => onEdit(row)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontSize: "16px",
                            }}
                          >
                            Update
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            color="primary"
                            onClick={() => onEdit(row)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontSize: "16px",
                            }}
                          >
                            View
                          </Typography>
                        )}
                      </>
                    </TableCell>
                  )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
