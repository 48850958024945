import Home from "src/pages";
import AddImplementingOfficer from "src/pages/add-implementing-officer";
import AdminLogin from "src/pages/admin-login";
import DemandNote from "src/pages/demand-note";
import ForgetPassword from "src/pages/forget-password";
import LandOwnerShipDetailView from "src/pages/landownership-details";
import Login from "src/pages/login";
import Marketwatch from "src/pages/market-watch";
import MyProfile from "src/pages/my-profile";
import NotFound from "src/pages/not-found";
import AadhaarVerification from "src/pages/Onboarding/aadhaar-verification";
import AddressDetails from "src/pages/Onboarding/address-details";
import AgencyDetails from "src/pages/Onboarding/agency-details";
import BankDetails from "src/pages/Onboarding/bank-details";
import ContactDetails from "src/pages/Onboarding/contact-details";
import DocumentUpload from "src/pages/Onboarding/document-upload";
import EntityDetails from "src/pages/Onboarding/entity-details";
import ImplementingOfficerDetails from "src/pages/Onboarding/implementing-officer-details";
import NodalOfficerDetails from "src/pages/Onboarding/nodal-officer-details";
import OrganizationDetails from "src/pages/Onboarding/organization-detail";
import PersonalDetails from "src/pages/Onboarding/personal-details";
import UserTypeDetails from "src/pages/Onboarding/user-type-details";
import ForestOrderBook from "src/pages/order-book";
import PrivacyPolicy from "src/pages/privacy-policy";
import ProjectDetailView from "src/pages/project-detail-view";
import ResetPassword from "src/pages/reset-password";
import DemandNoteView from "src/pages/view-demand-draft";
import { path } from "./path";
import ChangePassword from "src/pages/change-password";
import DefaultFundApplication from "src/pages/raise-application";
import DefaultFillFundApplication from "src/pages/fill-fund-application";
import FundApplicationDetailView from "src/pages/fund-application-details";
import DefaultViewFundApplication from "src/pages/view-fund-application";
import EcoRestorationProgress from "src/pages/eco-restoration-progress";
import CostEstimateDetails from "src/pages/costEstimateDetailView";
import AdvanceWorkProgress from "src/components/feature/AdvanceWorkProgress";
import DefaultAdvanceWorkProgress from "src/pages/advance-work-progress";
import CostEstimateDigital from "src/pages/cost-estimate-digital";
import UpdateProgressDetails from "src/pages/update-progress-details";

type RouteCOnfig = {
  path?: string;
  element: () => JSX.Element;
  isPublic?: boolean;
  index?: boolean;
};

export type RouteType<T extends string = string> = Record<
  keyof typeof path,
  RouteCOnfig
>;

const createRoute = <T extends string>(data: RouteType<T>) => data;

export const routes = createRoute({
  home: {
    path: path.home,
    element: Home,
  },
  orderBook: {
    path: path.orderBook,
    element: ForestOrderBook,
  },
  notFound: {
    path: path.notFound,
    element: NotFound,
  },
  adminLogin: {
    path: path.adminLogin,
    element: AdminLogin,
  },
  signIn: {
    path: path.signIn,
    element: AdminLogin,
  },
  privacyPolicy: {
    path: path.privacyPolicy,
    element: PrivacyPolicy,
  },
  // onboarding
  onboarding: {
    path: path.onboarding,
    element: UserTypeDetails,
  },
  personalDetails: {
    path: path.personalDetails,
    element: PersonalDetails,
  },
  addressDetails: {
    path: path.addressDetails,
    element: AddressDetails,
  },
  aadhaarVerification: {
    path: path.aadhaarVerification,
    element: AadhaarVerification,
  },
  organizationDetails: {
    path: path.organizationDetails,
    element: OrganizationDetails,
  },
  documentUpload: {
    path: path.documentUpload,
    element: DocumentUpload,
  },
  marketWatch: {
    path: path.marketWatch,
    element: Marketwatch,
  },
  login: {
    path: path.login,
    element: Login,
  },
  forgetPassword: {
    path: path.forgetPassword,
    element: ForgetPassword,
  },
  myProfile: {
    path: path.myProfile,
    element: MyProfile,
  },
  projectDetailView: {
    path: path.projectDetailView,
    element: ProjectDetailView,
  },
  resetPassword: {
    path: path.resetPassword,
    element: ResetPassword,
  },
  bankDetails: {
    path: path.bankDetails,
    element: BankDetails,
  },
  nodalOfficerDetails: {
    path: path.nodalOfficerDetails,
    element: NodalOfficerDetails,
  },
  implementingOfficerDetails: {
    path: path.implementingOfficerDetails,
    element: ImplementingOfficerDetails,
  },
  agencyDetails: {
    path: path.agencyDetails,
    element: AgencyDetails,
  },
  entityDetails: {
    path: path.entityDetails,
    element: EntityDetails,
  },
  contactDetails: {
    path: path.contactDetails,
    element: ContactDetails,
  },
  demandNote: {
    path: path.demandNote,
    element: DemandNote,
  },
  demandNoteView: {
    path: path.demandNoteView,
    element: DemandNoteView,
  },
  landOwnerShipDetailView: {
    path: path.landOwnerShipDetailView,
    element: LandOwnerShipDetailView,
  },
  addImplementingOfficer: {
    path: path.addImplementingOfficer,
    element: AddImplementingOfficer,
  },
  changePassword: {
    path: path.changePassword,
    element: ChangePassword,
  },
  fundApplication: {
    path: path.fundApplication,
    element: DefaultFundApplication,
  },
  fillFundApplication: {
    path: path.fillFundApplication,
    element: DefaultFillFundApplication,
  },
  fundApplicationDetails: {
    path: path.fundApplicationDetails,
    element: FundApplicationDetailView,
  },
  viewFundApplicationDetails: {
    path: path.viewFundApplicationDetails,
    element: DefaultViewFundApplication,
  },
  ecoRestorationProgress: {
    path: path.ecoRestorationProgress,
    element: EcoRestorationProgress,
  },
  costEstimateDetails: {
    path: path.costEstimateDetails,
    element: CostEstimateDetails,
  },
  advanceWorkProgress: {
    path: path.advanceWorkProgress,
    element: DefaultAdvanceWorkProgress,
  },
  costEstimateDigital: {
    path: path.costEstimateDigital,
    element: CostEstimateDigital,
  },
  updateProgressDetails: {
    path: path.updateProgressDetails,
    element: UpdateProgressDetails,
  },
});

type RoutePath = Record<keyof typeof routes, Pick<RouteCOnfig, "path">>;

export const routePath: RoutePath = Object.keys(routes).reduce(
  (value, route) => ({ ...value, [route]: { path: routes[route].path } }),
  {} as RoutePath
);
